import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Col, Container, Image } from "react-bootstrap";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import './GroupDisplayContentComponent.scss';

const GroupDisplayContentComponent = (props) => {
    const {group:currentGroup, setHasItemsCallback=null, publicOnly = false, items:injectedItems} = props;

    // get BACKOFFICE_DEFAULT_VALUES from apiData
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { apiData: { USER } } = useStoreState(state => state.actionSlugs);

    const {allVideos, isFetchingAllVideos} = useCustomGetStoreState("videos");
    const {allQuizzes, isFetchingAllQuizzes} = useCustomGetStoreState("quizzes");
    const {allGroups, isFetchingAllGroups} = useCustomGetStoreState("groups");
    const groupSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("groups"));
    const videosSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("videos"));
    const quizzesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("quizzes"));
    const {enumerateItemsInGroup} = useStoreActions(actions => actions.groups);


    const [items, setItems] = useState(injectedItems ?? []);

    useEffect(() => {
        if(!injectedItems && !(isFetchingAllQuizzes || isFetchingAllVideos || isFetchingAllGroups)) {
            setItems(enumerateItemsInGroup({group:currentGroup}));
        }
    },[allQuizzes, isFetchingAllQuizzes, allVideos, isFetchingAllVideos, allGroups, isFetchingAllGroups, currentGroup, publicOnly]);

    useEffect(() => {
        if(injectedItems && injectedItems.length !==0) {
            setItems(injectedItems);
        }
    },[injectedItems]);

    useEffect(() => {
        setHasItemsCallback?.(items.length > 0);   
    }, [items])

    const cardsItems = items
        .sort((a,b) => a.displayOrder - b.displayOrder)
        // .map((item) => <Col><Card><Card.img src={item.thumbnail} /></Card></Col> );
        .map((item) => {
            let link = null;
            switch (item.type) {
                case 'group':
                    link = groupSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                case 'video':
                    link = videosSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                case 'quiz':
                    link = quizzesSlugs.readOne.replace(':uniqueId', item.uniqueId);
                    break;
                default:
                    break;
            }
            let cardItem = 
                <Image 
                    className={"img-"+item.type} 
                    src={item.thumbnail} 
                    thumbnail 
                />;
                
            if(link) {
                cardItem = <Link  to={link}>{cardItem} </Link>;
            }

            return <Col key={item.uniqueId} className="item-thumbnail">{cardItem}</Col>
        });


    const customBackgroundImage = currentGroup?.links?.background;
    const defaultBackgroundImage = BACKOFFICE_DEFAULT_VALUES.bkgImage;
    const clientCustomBackgroundImage = USER?.client?.bkgImage;
    const backgroundImage = currentGroup?.idHeadset == 0 ?
        (clientCustomBackgroundImage ?? (customBackgroundImage ?? defaultBackgroundImage))
        : (customBackgroundImage ?? defaultBackgroundImage);
    
    return <Container className='group' style={{ backgroundImage: "url(" + backgroundImage + ")" }} >
        {cardsItems}
    </Container>;
}

export default GroupDisplayContentComponent;