import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {Container , Row, Button, Col} from 'react-bootstrap';
import { useMemo } from 'react';
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";
import UsersByClientComponent from '../../../users/UsersByClientComponent.jsx';
import CreateUpdateUserComponent, { submitUserForm } from '../../../users/CreateUpdateUserComponent.jsx';

import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
import { useToast } from '../../../../hooks/useToast.jsx';

const ClientUserTabComponent = ({clientById, setModalData, modalData, setModalShow, fromAccountPage=false}) => {

    const { createUser, setCreateFormErrors } = useStoreActions(actions => actions.users);
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const {client:userClientUniqueId} = useStoreState(state => state.auth);
    const { allUsers } = useCustomGetStoreState("users");
    const toast = useToast();

    const allUsersEmails = useMemo(() => {
        return allUsers
            ? allUsers.map(u => u.email)
            : [];
    }, [allUsers]);
    
    const CardClassName = fromAccountPage ? "tab-info-card tab-info-card--noMinHeight tab-info-card--noBackground-color" :"tab-info-card tab-info-card--noMinHeight";

    const canCreateUser = !AuthorizationChecker.hasReadAllRights('clientsCRM') && AuthorizationChecker.hasCreateRights('users');
    
    const createUserModal = () => {
        if(canCreateUser) {
            const validate = () => {
                const {formName} = ACTIONS['users']['create'];
                return submitUserForm(createUser, formName, clientById.uniqueId, null, allUsersEmails, true, false)
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .catch(error => {
                        error?.formError && setCreateFormErrors(error.formError);
                    })
            }
            setModalData({
                ...modalData,
                header: <>Ajout d'un⋅e utilisateur⋅rice</>,
                content: <CreateUpdateUserComponent action="create"/>,
                cancelButton: 'Annuler',
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    };

    return (
        <Container className={CardClassName} style={{width: "fit-content", minWidth: "90%"}}>
            <Row className='tab-info-card-header mb-3'>
                <Col className="header-heading">
                    <p className="header-desc">Gérez vos utilisateur⋅rices</p>
                </Col>      
                { canCreateUser &&
                <Col className="header-action">
                    <Button variant="success" onClick={() => {
                        createUserModal();
                        }
                    }><i className="fas fa-plus"></i> &nbsp;Ajout utilisateur⋅rice</Button>
                </Col>
                }
            </Row>
            <Row>
                <Col><UsersByClientComponent client={clientById}/></Col>
            </Row>
        </Container>
    );
}

export default ClientUserTabComponent;