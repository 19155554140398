import React, { useEffect, useState } from "react";
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useStoreState, useStoreActions } from "easy-peasy";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import CustomSelectSingleComponent from "../../widgets/CustomSelectComponent/CustomSelectSingleComponent";
import './formOneProComponent.scss'
import Loader from "../../widgets/Loader";

const FormOneProComponent = ({ pro = null, setLoadingValidation = null }) => {
    const { apiData: { BACKOFFICE_DEFAULT_VALUES, ACTIONS } } = useStoreState(state => state.actionSlugs);
    const token = pro ? ACTIONS?.professionals?.update?.csrfToken : ACTIONS?.videos?.addPro?.csrfToken;

    const {
        allRomes,
        allAreasOfInterest,
        allWorkingConditions,
        allSkills,
        isFetchingAllAreasOfInterest,
        isFetchingAllWorkingConditions,
        isFetchingAllSkills
    } = useCustomGetStoreState("romes");
    const { fetchOneRomeById, fetchAllAreasOfInterest, fetchAllWorkingConditions, fetchAllSkills } = useStoreActions(actions => actions.romes);

    useEffect(() => {
        Promise.all([
            allAreasOfInterest.length === 0 && !isFetchingAllAreasOfInterest && fetchAllAreasOfInterest(),
            allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions && fetchAllWorkingConditions(),
            allSkills.length === 0 && !isFetchingAllSkills && fetchAllSkills()
        ])
    }, [])

    const [isFirstMainAppellationsLoadDone, setIsFirstMainAppellationsLoadDone] = useState(false);
    const [isFirstSecondaryAppellationsLoadDone, setIsFirstSecondaryAppellationsLoadDone] = useState(false);
    const [selectedMainRomes, setSelectedMainRomes] = useState([]);
    const [allMainJobAppellations, setAllMainJobAppellations] = useState([]);
    const [selectedMainJobAppellationCodes, setSelectedMainJobAppellationCodes] = useState([]);
    const [selectedSecondaryRomes, setSelectedSecondaryRomes] = useState([]);
    const [allSecondaryJobAppellations, setAllSecondaryJobAppellations] = useState([]);
    const [selectedSecondaryJobAppellationCodes, setSelectedSecondaryJobAppellationCodes] = useState([]);
    const [selectedAreasOfInterest, setSelectedAreasOfInterest] = useState([]);
    const [selectedWorkingConditions, setSelectedWorkingConditions] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [isFetchingMainAppelations, setIsFetchingMainAppelations] = useState(false);
    const [isFetchingSecondaryAppelations, setIsFetchingSecondaryAppelations] = useState(false);

    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const [thumbnailFileInputName, setThumbnailFileInputName] = useState('Choisir le fichier');

    useEffect(() => {
        setLoadingValidation(!isFirstMainAppellationsLoadDone || !isFirstSecondaryAppellationsLoadDone);
    }, [isFirstMainAppellationsLoadDone, isFirstSecondaryAppellationsLoadDone])

    useEffect(() => {
        if (pro) {
            setSelectedMainRomes(pro.mainJob?.rome ? [pro.mainJob.rome] : []);
            setSelectedMainJobAppellationCodes(pro?.mainJob?.code ? [pro?.mainJob?.code] : []);
            setSelectedSecondaryRomes(pro.jobAppellations?.map(jobAppellation => jobAppellation.rome) ?? []);
            setSelectedSecondaryJobAppellationCodes(pro.jobAppellations?.map(jobAppellation => jobAppellation.code) ?? []);
            setSelectedAreasOfInterest(pro.areasOfInterest?.map(areaOfInterest => areaOfInterest.code) ?? []);
            setSelectedWorkingConditions(pro?.workingConditions?.map(workingCondition => workingCondition.code) ?? []);
            setSelectedSkills(pro?.softSkills?.map(softSkill => softSkill.code) ?? []);
        } else {
            setIsFirstMainAppellationsLoadDone(true);
            setIsFirstSecondaryAppellationsLoadDone(true);
        }
    }, [])

    useEffect(() => {
        if (selectedMainRomes.length > 0) {
            setIsFetchingMainAppelations(true);
            fetchOneRomeById(selectedMainRomes[0]).then((data) => {
                setAllMainJobAppellations(data.appellations);
                isFirstMainAppellationsLoadDone
                    ? setSelectedMainJobAppellationCodes([])
                    : setIsFirstMainAppellationsLoadDone(true);
                setIsFetchingMainAppelations(false);
            });
        } else if (isFirstMainAppellationsLoadDone) {
            setAllMainJobAppellations([]);
            setSelectedMainJobAppellationCodes([]);

        } else if (!pro?.mainJob) {
            setIsFirstMainAppellationsLoadDone(true);
        }
    }, [selectedMainRomes])

    useEffect(() => {
        if (selectedSecondaryRomes.length > 0) {
            setIsFetchingSecondaryAppelations(true);
            // Update secondary jobs appellations if their ROME code is removed 
            if (isFirstSecondaryAppellationsLoadDone) {
                setSelectedSecondaryJobAppellationCodes(selectedSecondaryJobAppellationCodes => selectedSecondaryJobAppellationCodes.filter(selectedSecondaryJob => {
                    const secondaryJobAppellation = allSecondaryJobAppellations.find(job => job.code === selectedSecondaryJob);
                    return secondaryJobAppellation
                        ? selectedSecondaryRomes?.some(selectedSecondaryRome => selectedSecondaryRome === secondaryJobAppellation.rome)
                        : false;
                }));

                setAllSecondaryJobAppellations(secondaryJobAppellations => secondaryJobAppellations.filter(secondaryJobAppellation =>
                    selectedSecondaryRomes?.some(selectedSecondaryRome => selectedSecondaryRome === secondaryJobAppellation.rome)
                ));
            }

            // Fetch rome by id content only if not already fetched
            selectedSecondaryRomes.forEach((selectedSecondaryRome, index) => {
                if (!allSecondaryJobAppellations.some(secondaryJobAppellation => secondaryJobAppellation.rome === selectedSecondaryRome)) {
                    fetchOneRomeById(selectedSecondaryRome).then(data => {
                        if (data.appellations) {
                            setAllSecondaryJobAppellations(prev => [...prev, ...data.appellations]);
                            !isFirstSecondaryAppellationsLoadDone && setIsFirstSecondaryAppellationsLoadDone(true);
                        }
                        if (index === selectedSecondaryRomes.length - 1) {
                            setIsFetchingSecondaryAppelations(false);
                        }
                    })
                } else if (index === selectedSecondaryRomes.length - 1) {
                    setIsFetchingSecondaryAppelations(false);
                }
            });
        } else if (isFirstSecondaryAppellationsLoadDone) {
            setAllSecondaryJobAppellations([]);
            setSelectedSecondaryJobAppellationCodes([]);
        } else if (pro?.jobAppellations?.length === 0) {
            setIsFirstSecondaryAppellationsLoadDone(true);
        }
    }, [selectedSecondaryRomes])

    return <Form id="video_pro_form">
        <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
        <Row>
            <Col>
                <Form.Group className="formSection">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                        name="name"
                        defaultValue={pro?.name ?? null}
                        placeholder="Nom"
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="formSection">
                    <Form.Label>Photo de profil ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
                    <div className="input-group mb-4">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="thumb"
                            name="thumb"
                            accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                            onChange={(event) => { setThumbnailFileInputName(event.target.files[0].name) }}
                        />
                        <label className="custom-file-label" htmlFor="thumb" data-browse="Parcourir">
                            {thumbnailFileInputName}
                        </label>
                    </div>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <h5 className="mb-0 mt-2">Métier principal</h5>
            <Col>
                <Form.Group className="formSection d-flex flex-column mt-1">
                    <Form.Label>Code ROME métier principal</Form.Label>
                    <CustomSelectSingleComponent
                        selectId="mainRome"
                        options={allRomes}
                        nameSelect="mainRome"
                        selectedOptions={selectedMainRomes}
                        setSelectedOptions={setSelectedMainRomes}
                        optionLabel="label"
                        valueLabel="codeRome"
                        optionIndex="codeRome"
                    />
                </Form.Group>
            </Col>

            <Col>
                <Form.Group className="formSection d-flex flex-column mt-1">
                    <Form.Label>Appellation métier principal</Form.Label>
                    {isFetchingMainAppelations
                        ? <Loader />
                        : <CustomSelectSingleComponent
                            selectId="mainJob"
                            options={allMainJobAppellations}
                            nameSelect="mainJob"
                            selectedOptions={selectedMainJobAppellationCodes}
                            setSelectedOptions={setSelectedMainJobAppellationCodes}
                            optionLabel="label"
                            valueLabel="code"
                        />}
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <h5 className="mb-0 mt-2">Autes métiers</h5>
            <Col>
                <Form.Group className="formSection d-flex flex-column mt-1">
                    <Form.Label>Codes ROME autres métiers</Form.Label>
                    <SelectMultipleItemsWithSearchBar
                        idSelector="secondaryRome"
                        allItems={allRomes}
                        selectedItemKeys={selectedSecondaryRomes}
                        setSelectedItemKeys={setSelectedSecondaryRomes}
                        itemKey="codeRome" itemValue="codeRome"
                        itemValueDescription="label"
                    />
                </Form.Group>
            </Col>

            <Col>
                <Form.Group className="formSection d-flex flex-column mt-1">
                    <Form.Label>Appellations autres métiers</Form.Label>
                    {isFetchingSecondaryAppelations
                        ? <Loader />
                        : <SelectMultipleItemsWithSearchBar
                            idSelector="appellations[]"
                            allItems={allSecondaryJobAppellations}
                            selectedItemKeys={selectedSecondaryJobAppellationCodes}
                            setSelectedItemKeys={setSelectedSecondaryJobAppellationCodes}
                            itemKey="code" itemValue="label"
                        />}
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col>
                <Form.Group className="formSection d-flex flex-column">
                    <Form.Label>Centres d'intérêt</Form.Label>
                    <SelectMultipleItemsWithSearchBar
                        idSelector="areasOfInterest[]"
                        allItems={allAreasOfInterest}
                        selectedItemKeys={selectedAreasOfInterest} setSelectedItemKeys={setSelectedAreasOfInterest}
                        itemKey="code" itemValue="label"
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="formSection d-flex flex-column">
                    <Form.Label>Savoir-être professionnel</Form.Label>
                    <SelectMultipleItemsWithSearchBar
                        idSelector="softSkills[]"
                        allItems={allSkills}
                        selectedItemKeys={selectedSkills} setSelectedItemKeys={setSelectedSkills}
                        itemKey="code" itemValue="code" itemValueDescription="label"
                    />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className="col-6">
                <Form.Group className="formSection d-flex flex-column">
                    <Form.Label>Contexte de travail</Form.Label>
                    <SelectMultipleItemsWithSearchBar
                        idSelector="workingConditions[]"
                        allItems={allWorkingConditions}
                        selectedItemKeys={selectedWorkingConditions} setSelectedItemKeys={setSelectedWorkingConditions}
                        itemKey="code" itemValue="code" itemValueDescription="label"
                    />
                </Form.Group>
            </Col>
        </Row>
    </Form>;
}

export const submitProVideoForm = ({ uniqueId, action }) => {
    const formData = new FormData(document.getElementById('video_pro_form'));
    const payload = { uniqueId, formData: formData };
    return action(payload);
}

FormOneProComponent.propTypes = {
    pro: PropTypes.object,
    setLoadingValidation: PropTypes.func
};

export default FormOneProComponent;