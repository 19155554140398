import React from "react";
import { Tabs, Tab, Row, ListGroup, Button } from "react-bootstrap";
import PropTypes from 'prop-types';

import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

import RomeSkillsTabComponent from "../romeDetail/romeDetailTabs/RomeSkillsTabComponent.jsx";
import RomeItemsTabComponent from "../romeDetail/romeDetailTabs/RomeItemsTabComponent.jsx";
import Loader from "../../../components/widgets/Loader";

import './RomeDetailViewComponent.scss';
import RomeConditionsTabComponent from "../romeDetail/romeDetailTabs/RomeConditionsTabComponent.jsx";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

const RomeDetailViewComponent = (props) => {
    const {rome} = props;

    const areaOfInterestsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("areasOfInterest"));
    const largeProfessionalFieldsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('largeProfessionalFields'));
    const oneAreaOfInterestSlug = areaOfInterestsSlugs?.readOne ?? null;

    const centreInterets = rome 
        && rome?.centreInterets?.length !== 0 
        && rome?.centreInterets
            ?.map(interest => <li className="mt-2 ms-3" key={'aoi_'+interest.code}>
                {oneAreaOfInterestSlug
                    ? <Link to={oneAreaOfInterestSlug.replace(':uniqueId', interest.code)}>{interest.label}</Link>
                    : <>{ interest.label }</>}
                </li>
            );

    const largeProfessionalFieldUrl = largeProfessionalFieldsSlugs?.readOne?.replace(':uniqueId', rome.largeProfessionalField?.code);
    const professionalFieldUrl = largeProfessionalFieldsSlugs?.readOne?.replace(':uniqueId', rome.largeProfessionalField?.code)
        + "?professionalField=" + rome.professionalField?.code;

    return (
    <>
     { rome
        ? <>
                    {largeProfessionalFieldUrl && professionalFieldUrl && 
                        <Row className="mt-4">
                            <div className="d-flex mb-4 rome-domain-links">
                                <h5 className="my-0 me-2">Domaine professionnel : </h5>
                                <Link to={largeProfessionalFieldUrl}>
                                    <Button variant='light' className="me-2" style={{ whiteSpace: "nowrap" }}>
                                        {rome.largeProfessionalField?.label + " (" + rome.largeProfessionalField?.code + ")"}
                                    </Button>
                                </Link>
                            </div>
                            <div className="d-flex mb-5 rome-domain-links">
                                <h5 className="my-0 me-2">Sous-domaine professionnel : </h5>
                                <Link to={professionalFieldUrl}>
                                    <Button variant='light' className="me-2" style={{ whiteSpace: "nowrap" }}>
                                        {rome.professionalField?.label + " (" + rome.professionalField?.code + ")"}
                                    </Button>
                                </Link>
                            </div>
                        </Row>}
                    <Row>
                        <h4 className="fw-bold">Caractéristiques du métier</h4>
                        <RomeItemsTabComponent rome={rome} tabName="definition" />
                        <ListGroup.Item className="content-text">
                            Riasec majeur : {HelperMetiers360.riasecLabel(rome?.riasecMajeur)} - riasec mineur : {HelperMetiers360.riasecLabel(rome?.riasecMineur)}
                        </ListGroup.Item>
                    </Row>
        <Row className="mt-4">
            <h4 className="fw-bold">Comment y accéder ?</h4>
            <RomeItemsTabComponent rome={rome} tabName="access"/>
        </Row>
        <Row className="mt-4">
            <Tabs defaultActiveKey="areasOfInterest">
                            <Tab eventKey="areasOfInterest" title={<><i className="fa fa-heart me-2" />Centres d'intérêt</>} unmountOnExit={true}> 
                    <div className="mt-4">{centreInterets}</div>
                </Tab>
                            <Tab eventKey="workingConditions" title={<><i className="fa fa-briefcase me-2" />Contextes de travail</>} unmountOnExit={true}>
                    <RomeConditionsTabComponent rome = {rome} />
                </Tab>
                <Tab eventKey="skills" title="Compétences" unmountOnExit={true}>
                                <RomeSkillsTabComponent className="mt-4" rome={rome} skillsType='competencesMobiliseesPrincipales' title="Compétences mobilisées émergentes" />
                                <RomeSkillsTabComponent className="mt-5" rome={rome} skillsType='competencesMobilisees' title="Compétences mobilisées" />
                                <RomeSkillsTabComponent className="mt-5" rome={rome} skillsType='competencesMobiliseesEmergentes' title="Compétences mobilisées émergentes" />
                </Tab>
            </Tabs>
        </Row>
        <div className="footer mt-5">
                        Source : <a href="https://francetravail.io" target="_blank">France Travail</a>
            {rome.updatedAt?.date &&<> (Mise à jour : {HelperMetiers360.getdisplayDateType(rome.updatedAt.date, 'day')})</>}
        </div>
        </>
        : <Loader />}
    </>)
  
        
}

RomeDetailViewComponent.propTypes = {
    rome: PropTypes.object.isRequired
};

export default RomeDetailViewComponent;