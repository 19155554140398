import React, { useRef } from 'react';
import { Form, Row, Col, Badge } from 'react-bootstrap';
import useDebounceSearch from "../../../hooks/useDebounchSearch.jsx";
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';
import PropTypes from 'prop-types';
import './SelectMultipleItemsWithSearchBar.scss';
import CustomSelectMultipleComponent from  "../../widgets/CustomSelectComponent/CustomSelectMultipleComponent.jsx"


/**
 * Select multiple items with search bar and badges of selected items Component
 *
 * @version 1.0.0
 * @param {string} idSelector  id of the form control
 * @param {string} label  label to display above the form control
 * @param {array} allItems  all list items
 * @param {array} selectedItemKeys  init selected items
 * @param {function} setSelectedItemKeys  callback function
 * @param {string} itemKey  name of the key to identify item in allItems list
 * @param {string} itemValue  name of the value identifier in allItems list to display item value
 * @example
 *      <SelectMultipleItemsWithSearchBar idSelector={idSelector} label={label} allItems={allItems} 
 *          selectedItemKeys={selectedItemKeys} setSelectedItemKeys={setSelectedItemKeys} 
 *          itemKey={itemKey} itemValue={itemValue} />
 */

 const SelectMultipleItemsWithSearchBar = (props) => {
     const { idSelector, label, allItems, selectedItemKeys, setSelectedItemKeys, itemKey, itemValue, disabled = false, itemValueDescription = null } = props;

    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher par mots-clés', delay: 400});

    const removeItem = (itemKeyToRemove) => {
        const newSelectedItemKeys = selectedItemKeys.filter(item => item !== itemKeyToRemove);
        setSelectedItemKeys(newSelectedItemKeys);
    }

    const badgesOfSelectedItems = <div className='d-flex flex-wrap'>
        { allItems 
            ?.filter(item => selectedItemKeys.includes((item[itemKey])))
            ?.map(item => 
                <Badge key={item[itemKey]} variant="primary" className="token-card me-1 mb-1" 
                    onClick={() => removeItem(item[itemKey])}>
                    {item[itemValue] + (itemValueDescription ? " - " + item[itemValueDescription] : "")}
                </Badge>
        )}
    </div>;

    const itemsOptions = allItems
        ?.sort((a,b) => HelperMetiers360.localeCompareWithNullable(a[itemValue], b[itemValue]))
        .filter(item => HelperMetiers360.isSearchInText(search, item[itemValue] + (itemValueDescription && item[itemValueDescription])));

    return <Row className='w-100'>
        {label && <Col lg="12">
            <Form.Label>{label}</Form.Label>
        </Col>}

        { <div className='select-items-widget'>
            { !disabled
                && <>
                    { badgesOfSelectedItems }
                    {allItems?.length > 10 && <div className='search-group'>{searchInput}</div>}
                </>}

            <div className="customSelect">
                <CustomSelectMultipleComponent
                    options={itemsOptions}
                    selectOptions={setSelectedItemKeys}
                    selectedOptions={selectedItemKeys}
                    optionLabel={itemValue}
                    optionLabelDescription={itemValueDescription}
                    valueLabel={itemKey}
                    disabled={disabled}
                    displayNoneOption={!search}
                />
            </div>

            { // transform the list of selected ids in different input, compatible with the current way of sending forms data
                    selectedItemKeys
                    .filter(sel => sel && sel  !== "")
                    .map(
                    (sel,i) => <input key={`${sel}${i}`} type="hidden" value={sel} name={idSelector} id={`${idSelector}${i}`} />
                    )
            }

        </div>}
    </Row>;
}

SelectMultipleItemsWithSearchBar.propTypes = {
    idSelector: PropTypes.string.isRequired,
    label: PropTypes.string,
    allItems: PropTypes.array,
    selectedItemKeys: PropTypes.array.isRequired,
    setSelectedItemKeys: PropTypes.any.isRequired,
    itemKey: PropTypes.string.isRequired,
    itemValue: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    itemValueDescription: PropTypes.string
};

export default SelectMultipleItemsWithSearchBar