import { action, actionOn, thunk } from "easy-peasy";
import axios from "../services/axios";

const groupsInitialState = {
    allGroups: [],
    groupById: null,
    isFetchingAllGroups: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    isFetchingOneGroup: false,
}

const groups = {
    ...groupsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allGroups: state.allGroups,
                    groupById: state.groupById,
                    isFetchingAllGroups: state.isFetchingAllGroups,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    isFetchingOneGroup: state.isFetchingOneGroup,
                } = groupsInitialState);
            }
        }
    ),

    // handling all groups
    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),


    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),
    setAllGroups: action((state, payload) => {
        state.allGroups = payload;
    }),
    setIsFetchingAllGroups: action((state, payload) => {
        state.isFetchingAllGroups = payload;
    }),

    fetchGroups: thunk((actions, payload, helpers) => {
        const { isFetchingAllGroups, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getAllUrl = ACTIONS?.groups?.readAll?.url;
        if (!isFetchingAllGroups && isUpdateNecessary) {
            actions.setIsFetchingAllGroups(true);
            return axios.get(getAllUrl)
                .then((data) => {
                    actions.setAllGroups(data);
                    actions.setIsFetchingAllGroups(groupsInitialState.isFetchingAllGroups);
                    actions.setIsUpdateNecessary(groupsInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
        } else {
            return Promise.resolve();
        }
    }),

    enumerateItemsInGroup: thunk((actions, payload, helpers) => {
        const newItems = [];
        const { allGroups } = helpers.getState();
        const { allVideos } = helpers.getStoreState().videos;
        const { allQuizzes } = helpers.getStoreState().quizzes;
        const {group} = payload;

        if (group && (allGroups.length || allVideos.length || allQuizzes.length)) {
            allGroups.forEach(g => {
                const groupInSelectedGroup = g.groups?.find((item) => item.uniqueId === group.uniqueId);
                if (!!groupInSelectedGroup) {
                    newItems.push({
                        uniqueId: g.uniqueId,
                        displayOrder: groupInSelectedGroup.displayOrder,
                        thumbnail: g.links.thumbnail,
                        name: g.name,
                        type: 'group',
                    });
                }
            });
            allVideos.forEach(video => {
                const videoInSelectedGroup = video.groups?.find((item) => item.uniqueId === group.uniqueId);
                if (!!videoInSelectedGroup) {
                    newItems.push({
                        uniqueId: video.uniqueId,
                        displayOrder: videoInSelectedGroup.displayOrder,
                        thumbnail: video.links.thumbnail,
                        name: video.videoName,
                        type: 'video',
                    });
                }
            });
            allQuizzes.forEach(quiz => {
                const quizInSelectedGroup = quiz.groups?.find((item) => item.uniqueId === group.uniqueId);
                if (!!quizInSelectedGroup) {
                    newItems.push({
                        uniqueId: quiz.uniqueId,
                        displayOrder: quizInSelectedGroup.displayOrder,
                        thumbnail: quiz.links.thumbnail,
                        name: quiz.name,
                        type: 'quiz',
                    });
                }
            });
        }

        return newItems; 
    }),

    // handling one group
    setGroupById: action((state, payload) => {
        state.groupById = payload;
    }),
    setIsFetchingOneGroup: action((state, payload) => {
        state.isFetchingOneGroup = payload;
    }),

    fetchGroupById: thunk((actions, payload, helpers) => {
        const { isFetchingOneGroup } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getOneUrl = ACTIONS?.groups?.readOne?.url?.replace('uniqueId', payload);

        if (!isFetchingOneGroup) {
            actions.setIsFetchingOneGroup(true);
            actions.setGroupById(groupsInitialState.groupById);
            return axios.get(getOneUrl)
                .then((data) => {
                    actions.setGroupById(data);
                    return data;
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    actions.setIsFetchingOneGroup(groupsInitialState.isFetchingOneGroup);
                })
        } else {
            return Promise.resolve();
        }
    }),

    addGroup: action((state, payload) => {
        state.allGroups.push(payload);
    }),

    updateAllGroups: action((state, payload) => {
        const index = state.allGroups.findIndex((group) => group.uniqueId === payload.uniqueId);

        state.allGroups[index] = {
            ...state.allGroups[index],
            ...payload,
        };
    }),

    removeFromAllGroups: action((state, payload) => {
        const indexToRemove = state.allGroups.findIndex(group => group.uniqueId === payload);
        state.allGroups.splice(indexToRemove, 1);
    }),

    updateAllMediumAffected: thunk((state, payload, helpers) => {
        const { updateAllQuizzes } = helpers.getStoreActions().quizzes;
        const { updateAllVideos } = helpers.getStoreActions().videos;

        for (const item of payload) {
            switch (item.type) {
                case 'video':
                    updateAllVideos({ groups: item.groups, uniqueId: item.uniqueId });
                    break;
                case 'quiz':
                    updateAllQuizzes({ groups: item.groups, uniqueId: item.uniqueId });
                    break;
                case 'group':
                    state.updateAllGroups({ groups: item.groups, uniqueId: item.uniqueId })
                    break;
                default:
                    break;
            }
        }

    }),

    // posting one group
    postGroup: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const createGroupUrl = ACTIONS?.groups?.create?.url;

        const { formData } = payload;

        return axios.post(createGroupUrl, formData)
            .then((result) => {
                const { itemsToUpdate, groupToUpdate } = result;
                actions.addGroup(groupToUpdate);
                actions.updateAllMediumAffected(itemsToUpdate);
                const { setIsUpdateNecessary: setPedagogicModulesIsUpdateNecessary } = helpers.getStoreActions().pedagogicModules;
                setPedagogicModulesIsUpdateNecessary(true);
                return result;
            });
    }),

    // update one group
    updateGroup: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const { formData, uniqueId } = payload;

        const updateGroupUrl = ACTIONS?.groups?.update?.url?.replace('uniqueId', uniqueId);
        return axios.post(updateGroupUrl, formData)
            .then((result) => {
                const { itemsToUpdate, groupToUpdate } = result;
                actions.updateAllGroups(groupToUpdate);
                actions.updateAllMediumAffected(itemsToUpdate);
                const { setIsUpdateNecessary: setPedagogicModulesIsUpdateNecessary } = helpers.getStoreActions().pedagogicModules;
                setPedagogicModulesIsUpdateNecessary(true);
                return result;
            })
            .catch(error => Promise.reject(error))
    }),

    // delete one group
    deleteGroup: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const { uniqueId } = payload;

        const formData = new FormData();
        formData.set(`${ACTIONS?.groups?.delete?.formName}[_token]`, ACTIONS?.groups?.delete?.csrftoken);
        const deleteGroupUrl = ACTIONS?.groups?.delete?.url?.replace('uniqueId', uniqueId);
        return axios.post(deleteGroupUrl, formData)
            .then((result) => {
                actions.setGroupById(groupsInitialState.groupById);
                actions.removeFromAllGroups(uniqueId);
                actions.setIsUpdateNecessary(true);
                const { setIsUpdateNecessary: setPedagogicModulesIsUpdateNecessary } = helpers.getStoreActions().pedagogicModules;
                setPedagogicModulesIsUpdateNecessary(true);
                return result;
            })
            .catch(error =>{
                return Promise.reject(error);
            });
        }),

    // get remaining groups to create
    fetchRemainingSpecialGroups: thunk((actions, payload, helpers) => {
        const {apiData: {ACTIONS}} = helpers.getStoreState().actionSlugs;

        const getRemainingSpecialGroupsUrl = ACTIONS.groups?.getRemainingSpecialGroups?.url;
        if(getRemainingSpecialGroupsUrl) {
            return axios.get(getRemainingSpecialGroupsUrl)
            .then((result) => {
                return result;
            })
            .catch(error =>{
                throw error.errors;
            });
        } else {
            return Promise.reject('No route to fetch remaining special groups to create');
        }
    }),
};

export default groups;