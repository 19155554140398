import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { useParams } from "react-router-dom";

import { Row, Col, Container } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import useBackButton from "../../hooks/useBackButton";
import RomeDetailViewComponent from "../../components/romes/romeDetailView/RomeDetailViewComponent.jsx";
import RomeDetailsContentsComponent from "../../components/romes/romeDetail/RomeDetailsContentsComponent.jsx";

import './RomeDetailPage.scss';
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

const RomeDetailPage = () => {
    const { uniqueId } = useParams();

    const { romeById, isFetchingAllProfessionals } = useStoreState(state => state.romes);
    const { fetchRomeById } = useStoreActions(actions => actions.romes);

    const { allStories, isFetchingStories} = useCustomGetStoreState("stories");
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState("videos");
    const { isMobile } = useStoreState(state => state.responsive);

    const [isLoading, setIsLoading] = useState(false);

    const [romeStories, setRomeStories] = useState([]);
    const [romeVideos, setRomeVideos] = useState([]);
    const [isExpand, setIsExpand] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true);
            setIsExpand(false);
            fetchRomeById(uniqueId)
                .finally(() => setIsLoading(false));
        }
    }, [uniqueId]);

    useEffect(() => {
        if (romeById) {
            setRomeStories(allStories
                .filter((story => romeById.stories.includes(story.uniqueId)))
                .sort((a, b) => a.name - b.name));
            }
    }, [allStories, romeById])

    useEffect(() => {
        if (romeById) {
            setRomeVideos(allVideos?.filter((video => romeById?.videos?.length && romeById?.videos?.includes(video.uniqueId)))?.sort((a, b) => a.name - b.name));
        }
    }, [allVideos, romeById])

    const { backButtonComponent } = useBackButton();
    const [isAppellationsOpen, setIsAppellationsOpen] = useState(false);

    const appellations = romeById?.appellations?.length !==0
        && <ul className="appellations">{romeById?.appellations
            ?.sort((a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label))
            ?.map(appellation => <li key={HelperMetiers360.generateUniqueId()} className="appellation mt-2">
                {appellation.label}
            </li>)}
        </ul>

    return (
        <Container fluid>
            <Row className="item-page">
                {isMobile &&
                    <div className="d-flex justify-content-end">{backButtonComponent}</div>}
                <h2>
                    <span className="d-flex justify-content-between">
                        {romeById && romeById.code + " - "} {romeById?.label}
                        {!isMobile && backButtonComponent}
                    </span>
                    <button className="link-pull-down-button" onClick={() => setIsAppellationsOpen(prev => !prev)}>
                        Voir tous les métiers de ce code ROME
                        { isAppellationsOpen 
                            ? <i className="fa fa-caret-up ms-2"></i>
                            : <i className="fa fa-caret-down ms-2"></i>
                        }
                    </button>
                    {isAppellationsOpen && <div>
                        {appellations}
                    </div>}
                </h2>
            </Row>
            <Row>
                <Col className="col-7">
                    { (!romeById || isLoading)
                        ? <Loader />
                        : <RomeDetailViewComponent rome={romeById} />
                    }
                </Col>
                <div className={isExpand ? "expand-col" : "col col-5"}>
                    {(!romeById || isLoading)
                        ? <Loader />
                        : <RomeDetailsContentsComponent
                            romePros={romeById.professionals.sort((a, b) => a.name - b.name)}
                            romeStories={romeStories}
                            romeVideos={romeVideos}
                            isFetchingStories={isFetchingStories}
                            isFetchingAllVideos={isFetchingAllVideos}
                            isFetchingAllProfessionals={isFetchingAllProfessionals}
                            isExpand={isExpand}
                            setIsExpand={setIsExpand}
                        />}
                </div>
            </Row>
        </Container>

    )

}

export default RomeDetailPage;